import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import Nav from '../Components/Nav';
import Sidebar from '../Components/Sidebar';
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'
import InvestDeposit from '../Components/InvestDeposit';
import Tradingview from '../Components/Tradingview';
function TransferFunds() {
  const history = useNavigate();
  const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
  const [loading, setloading] = useState(false)

  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setloading(false)
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setloading(false)
          history("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          setloading(false)
          console.log(doc.data())
        } else {
          console.log("No such document!");
          setloading(false)
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };


  const [email, setemail] = useState("")
  const [amount, setamount] = useState("0")
  const Processing = async () => {
    if (parseFloat(userdetails.balance) < parseFloat(amount)) {
      Swal.fire(
        'Error !',
        ' your account balance is too low to make a transfer',
        'error'
      )
      return
    }

    setloading(true)
    await db.collection("users").where("email", "==", email)
      .get()
      .then((querySnapshot) => {
        console.log(querySnapshot.docs)
        if (querySnapshot.docs.length === 0) {
          Swal.fire(
            'Error Getting Account Details!',
            'Please check if account number is correct',
            'error'
          )
          return
        }
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          console.log(doc.data().firstname)
          Swal.fire({
            title: `Transfer  To Tradelitepro Account?`,
            text: `Transfer USD${amount} to ${doc.data().fullname}  Account`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, continue!'
          }).then((result) => {
            if (result.isConfirmed) {
              ///send funds to user
              const getUser = async () => {
                const userRef = db.collection("users").where("email", "==", email);
                const snapshot = await userRef.get();
                if (!snapshot.empty) {
                  const userData = snapshot.docs[0].data();
                  const userRef = db.collection("users").doc(snapshot.docs[0].id);
                  await userRef.update({ balance: (parseFloat(snapshot.docs[0].data().balance) + parseInt(amount)) });
                  updateUserBalance()
                }
              }
              getUser()
            }
          })
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        Swal.fire(
          'Error Getting Account Details!',
          'Please check if account number is correct',
          'error'
        )
      });

  }


  const updateUserBalance = async (bal) => {
    const newEarings = parseFloat(userdetails.balance) - parseFloat(amount);
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    var washingtonRef = await db.collection("users").doc(userids);
    washingtonRef
      .update({
        balance: newEarings,
      })
      .then(function () {
        console.log("Document successfully up2dated!");
        // alert("withdrawal was successful we will get back to you");
        Swal.fire({
          icon: 'success',
          title: 'Transfer was completed',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
    // fetchuserdata();
  };
  return (
    <div>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <title>TransferFunds  </title>
      <div className="wrapper">
        <Nav />
        <div id="loader" className="text-center card" style={{ position: 'fixed', display: 'none', left: 0, right: 0, top: 0, bottom: 0, zIndex: 9999, opacity: '0.855' }}>
          <div className="text-info text-center">
            <img className style={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-40px', marginLeft: '-40px' }} src="../util/logo/logo-icon.png" height="auto" width="60px" />
          </div>
        </div>
        <div className="main-panel">
          <div className="container">
            <div className="bg-primary2 pt-4 pb-5" style={{ marginBottom: '-80px' }}>
              <div className="container text-white py-2">
                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    <h2 className="mt-1">Transfer</h2>
                  </div>
                  <div className="ml-auto">
                  </div>
                </div>
              </div>
            </div>
            <div className="page-inner mt--5">
              <div className="row ">
                <div className="col-md-12 mt-3">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-head-row card-tools-still-right">
                        <h4 className="card-title">Transfer Request</h4>
                      </div>
                    </div>
                    <div className="card-body px-0">
                      <div className="row">
                        <div className="col-md-12 mb-2">
                          <div className="card">
                            <div className="card-body">
                              <form className="forms-sample">
                                <div className="form-group">
                                  <label className="form-control-label">Enter Receiver Email <span className="tx-danger">*</span></label>
                                  <input onkeyup="getval('smfn','mdname')" id="smfn" name="name" type="text" onChange={(e) => { setemail(e.target.value) }} placeholder="Receiver's Email" className="form-control" />
                                </div>
                                <div className="form-group">
                                  <label className="form-control-label">Enter Amount: <span className="tx-danger">*</span></label>
                                  <input className="form-control" type="number" onChange={(e) => { setamount(e.target.value) }}  id="smam" name="amount" />
                                </div>
                                <div className="modal fade" id="trns" tabIndex={-1} role="dialog" aria-labelledby="trns" aria-hidden="true">
                                  <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="card modal-content">
                                      <div className="modal-body text-center">
                                        <div className="card-title mb-3" style={{}}>Confirm Transfer</div>
                                        <h4>You are sending <span className="text-success">$ {amount}<span id="mdamt" /></span> to <span className="text-success"><span id="mdname" />{email}</span></h4>
                                        <br />
                                        <input type="hidden" name="id" defaultValue={562} />
                                      </div>
                                      <div className="modal-footer border-0 text-center">
                                        <button className="btn btn-success mr-2" type='button'  onClick={Processing} name="send_m">Send</button>
                                        <button type="button" className="btn btn-outline-warning" data-dismiss="modal" aria-label="Close">Cancel</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <button type="button" className="btn btn-primary btn-block"  data-toggle="modal" data-target="#trns">Make Transfer</button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
<Tradingview/>
              <h4 className="page-title text-primary">Hot Pricing Plans<span className="float-right">
                <a href="/Myplans" className="btn btn-primary btn-border btn-xs text-white">View All Plans</a>
              </span></h4>
<InvestDeposit/>
            </div>
          </div>
          <footer className="footer">
            <div className="container-fluid">
              <div className="language langBox" style={{}}>
                <img className="img-fluid mb-0" src="../images/icons/lang-b.png" alt="" style={{ height: '50px', width: 'auto' }} />
                <div id="google_translate_element" />
              </div>
              <nav className="pull-left">
                <ul className="nav">
                  <li className="nav-item">
                    <a className="nav-link" href="support.html">
                      Contact Support
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="Refferals">
                      My Referral
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="copyright ml-auto">
                2023 made with <a href="home.html">Eye-trades</a>
              </div>
            </div>
          </footer>
        </div>
      </div>
      <div className="watsPanel">
        <a href="#langDiv">
          <img className="img-fluid mb-0" src="../images/icons/lang.png" alt="" />
        </a>
        <hr className="m-2" />
        <a href="http://wa.me/newell" id="heheh">
          <img className="img-fluid" src="../images/icons/whatsapp.png" alt="" />
        </a>
        <a href="http://wa.me/newell">
          <img className="img-fluid" src="../images/icons/whatsapp-2.png" alt="" />
        </a>
      </div>
      {/* jQuery UI */}
      {/* jQuery Scrollbar */}
      {/* Moment JS */}
      {/* Chart JS */}
      {/* jQuery Sparkline */}
      {/* Chart Circle */}
      {/* Datatables */}
      {/* Bootstrap Notify */}
      {/* Bootstrap Toggle */}
      {/* jQuery Vector Maps */}
      {/* Google Maps Plugin */}
      {/* Dropzone */}
      {/* Fullcalendar */}
      {/* DateTimePicker */}
      {/* Bootstrap Tagsinput */}
      {/* Bootstrap Wizard */}
      {/* jQuery Validation */}
      {/* Summernote */}
      {/* Select2 */}
      {/* Sweet Alert */}
      {/* Owl Carousel */}
      {/* Magnific Popup */}
      {/* Atlantis JS */}
      {/* Code provided by Google */}
      {/* End custom js for this page */}
    </div>
  )
}

export default TransferFunds
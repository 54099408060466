import firebase from 'firebase';
const firebaseConfig = {
  apiKey: "AIzaSyD0sQ6BlyZI0V6s97iSMC647T4nMjDK_kI",
  authDomain: "eyetrades-3e08d.firebaseapp.com",
  databaseURL: "https://eyetrades-3e08d-default-rtdb.firebaseio.com",
  projectId: "eyetrades-3e08d",
  storageBucket: "eyetrades-3e08d.firebasestorage.app",
  messagingSenderId: "613932903600",
  appId: "1:613932903600:web:15fb4e6a5c6e230b5c5f75"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.firestore().settings({ timestampsInSnapshots: true })

export const f = firebase;
export const database = firebase.database();
export const storage = firebase.storage();
export const auth = firebase.auth();
export default firebase.firestore()
import React, { useState } from 'react';

// Define an array of investment plans


const InvestDeposit = () => {

    const investmentPlans = [
        {
            title: 'Beginner Plan',
            minDeposit: 20.0,
            maxDeposit: 500.0,
            dailyProfit: 20, // Representing the 20% profit
            planDuration: 1, // 24 hours = 1 day
            referralBonus: 0, // If not provided, assuming no referral bonus
            profitReturn: 20, // Overall profit
            radio: 80
        },
        {
            title: 'Crypto-Pro',
            minDeposit: 1000.0,
            maxDeposit: 4000.0,
            dailyProfit: 35, // Representing the 35% profit
            planDuration: 2, // Duration in days
            referralBonus: 0, // If not provided, assuming no referral bonus
            profitReturn: 35, // Overall profit
            radio: 81
        },
        {
            title: 'FX-Pro',
            minDeposit: 5000.0,
            maxDeposit: 9000.0,
            dailyProfit: 50, // Representing the 50% profit
            planDuration: 4, // Duration in days
            referralBonus: 0, // If not provided, assuming no referral bonus
            profitReturn: 50, // Overall profit
            radio: 82
        },
        {
            title: 'Stocks-&-Pre-IPOs',
            minDeposit: 10000.0,
            maxDeposit: Infinity, // Representing unlimited max deposit
            dailyProfit: 75, // Representing the 75% profit
            planDuration: 7, // Duration in days
            referralBonus: 0, // If not provided, assuming no referral bonus
            profitReturn: 75, // Overall profit
            radio: 83
        },
    ];
    

    const ListOfCoins = [

    ]
    const [Amount, setAmount] = useState(0)
    const [coinToUse, setcoinToUse] = useState(0)
    const [setselectedinvet, setsetselectedinvet] = useState(0)

  

    const processToInvestment = (investment) => {

        if(Amount != 0 && coinToUse != 0){

            const baseUrl = '/CompleteDeposit';
            const params = new URLSearchParams();
            params.append('Invetmentplan', investment);
            params.append('Coin', coinToUse);
            params.append('amount', Amount);
            const completeDepositUrl = baseUrl + '?' + params.toString();
    
            global.window && (global.window.location.href = completeDepositUrl)
        }else{
         alert("Fill IN THE REQUIRED FIELDS")
        }

    }

    return (
        <div className="row justify-content-center align-items-center">
            {investmentPlans.map((plan, index) => (
                <div className="col-md-3">
                    <div className="card-pricing2 card-primary">
                        <div className="pricing-header">
                            <h3 className="fw-bold">{plan.title}</h3>
                            <span className=" badge badge-light">Investment Plans</span>
                        </div>
                        <div className="price-value">
                            <div className="value">
                                <span className="currency" />
                                <span className="amount">{plan.dailyProfit}<span>%</span></span>
                                <span className="month">/Daily</span>
                            </div>
                        </div>
                        <ul className="pricing-content">
                            <li>Min Deposit <b className="float-right">${plan.minDeposit}</b></li>
                            <li>Max Deposit <b className="float-right">${plan.maxDeposit}</b></li>
                            <li>Daily Profit <b className="float-right">{plan.dailyProfit}%</b></li>
                            <li>Referral Bonus <b className="float-right">{plan.referralBonus}%</b></li>
                            <li>Plan Duration <b className="float-right">{plan.planDuration} Day(s)</b></li>
                            <li>Profit Return <b>{plan.profitReturn}% After {plan.planDuration} Day(s)</b></li>
                        </ul>
                        <form className="forms-sample" action="deposit.html" method="post">
                            <div className="form-group">
                                <label className="form-control-label">Enter Amount: <span className="text-danger">*</span></label>
                                <input type="number" name="amount" className="form-control" onChange={(e) => { setAmount(e.target.value) }} />
                            </div>
                            <input type="hidden" name="plan" defaultValue={plan.radio} />
                            <div className="form-group">
                                <label className="form-control-label">Choose Payment Method: <span className="text-danger">*</span></label>
                                <div className="row">
                                    <div className="col-6" onClick={() => { setcoinToUse("BTC") }}>
                                        <input type="radio" className="myRadio" id={`myRadio1` + plan.radio} name="walletType" defaultValue="btc" />
                                        <label htmlFor={`myRadio1` + plan.radio} className="myLabel"><img src="../images/coins/s-btc.png" /></label>
                                    </div>
                                    <div className="col-6" onClick={() => { setcoinToUse("ETH") }}>
                                        <input type="radio" className="myRadio" id={`myRadio2` + plan.radio} name="walletType" defaultValue="eth" />
                                        <label htmlFor={`myRadio2` + plan.radio} className="myLabel"><img src="../images/coins/s-eth.png" /></label>
                                    </div>
                                    <div className="col-6" onClick={() => { setcoinToUse("LTC") }}>
                                        <input type="radio" className="myRadio" id={`myRadio3` + plan.radio} name="walletType" defaultValue="ltc" />
                                        <label htmlFor={`myRadio3` + plan.radio} className="myLabel"><img src="../images/coins/s-ltc.png" /></label>
                                    </div>
                                    <div className="col-6" onClick={() => { setcoinToUse("USDT ERC20") }}>
                                        <input type="radio" className="myRadio" id={`myRadio4` + plan.radio} name="walletType" defaultValue="xrp" />
                                        <label htmlFor={`myRadio4` + plan.radio} className="myLabel"><img src="../images/coins/s-usdt.png" /></label>
                                    </div>
                                    <div className="col-6" onClick={() => { setcoinToUse("USDT Trc20") }}>
                                        <input type="radio" className="myRadio" id={`myRadio5` + plan.radio} name="walletType" defaultValue="usdt" />
                                        <label htmlFor={`myRadio5` + plan.radio} className="myLabel"><img src="../images/coins/s-usdt.png" /></label>
                                    </div>
                                    <div className="col-6" onClick={() => { setcoinToUse("WALLET") }}>
                                        <input type="radio" className="myRadio" id={`myRadio6` + plan.radio} name="walletType" defaultValue={1} />
                                        <label htmlFor={`myRadio6` + plan.radio} className="myLabel"><img src="../images/coins/s-bal.png" /></label>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-primary btn-lg w-75 fw-bold mb-3" name="invest" type="button" onClick={() => { processToInvestment(plan.title) }}>Make Deposit</button>
                        </form>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default InvestDeposit;
